import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';
import { Header } from '../Header/Header';
import { url } from '../Helpers/Constant';
import { Col, Row, Container } from 'react-bootstrap';
const BecomeBuyer = () => {
  const { user } = useAuthContext();
  const { register, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const onSubmit = (data) => {
    setIsLoading(true);
    setError('');

    fetch(`${url}/out/api/buyer/apply/${user.id}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setError('');
          setIsLoading(false);
          navigate('/buyer-account');
        } else {
          setIsLoading(false);
          setError(data.message);
        }
      });
  };
  return (
    <div>
      <Header />
      <div>
        <Container>
          <Row>
            <Col md={6}>
              <div className='text-center mt-5 '>
                <h3>Became a Buyer</h3>
              </div>
            </Col>
            <Col md={6}>
              <div className='mb-5'>
                <form onSubmit={handleSubmit(onSubmit)} className='fund'>
                  <label className='my-2 fw-bold'>Bank Name</label>
                  <br />
                  <input
                    {...register('bank_name')}
                    className='sign-up-input w-100'
                    placeholder='Bank Name'
                  />
                  <label className='my-2 fw-bold'>Bank Account</label>
                  <br />
                  <input
                    {...register('account_number')}
                    className='sign-up-input w-100'
                    type='number'
                    required
                    placeholder='Bank Account Number'
                  />

                  <p className='text-center text-danger my-2'>{error}</p>

                  {isLoading ? (
                    <input
                      type='Please wait...'
                      className='sign-up-input'
                      disabled
                    />
                  ) : (
                    <input type='submit' className='sign-up-input' />
                  )}
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BecomeBuyer;
