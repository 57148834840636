// import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from 'react-router-dom';
import HomeWrapper from './Components/HomeWrapper/HomeWrapper';
import AllServices from './Components/AllServices/AllServices';
import SingleServices from './Components/SingleServices/SingleServices';
import Message from './Components/MessageComponents/Message/Message';
import { Login } from './Components/AuthenticationPage/Login/Login';
import { Join } from './Components/AuthenticationPage/Join/Join';
import PrivateRoute from './Components/AuthenticationPage/PrivateRoute/PrivateRoute';
import BecomeBuyer from './Components/BecomeBuyer/BecomeBuyer';

import ForgotPassword from './Components/AuthenticationPage/ForgotPassword/ForgotPassword/ForgotPassword';
import SearchPage from './Components/SearchPage/SearchPage';
import SellerProfile from './Components/SellerProfile/SellerProfile';
import { NotFound } from './Components/NotFound/NotFound';
import { Footer } from './Components/Footer/Footer';
import { Profile } from './Components/Profile/Profile';

function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<HomeWrapper />} />
        <Route path='*' element={<NotFound />} />
        <Route path='/login' element={<Login />} />
        <Route path='/join/*' element={<Join />} />
        <Route path='/forgetpassword/*' element={<ForgotPassword />} />
        <Route path='/allservices/allcategory/*' element={<AllServices />} />
        <Route path='/allservices/:id/*' element={<SingleServices />} />
        <Route path='/result' element={<SearchPage />} />
        <Route
          path='/allservices/seller/:seller_id'
          element={<SellerProfile />}
        />

        <Route
          path='/message/*'
          element={
            <PrivateRoute>
              <Message />
            </PrivateRoute>
          }
        />
        <Route
          path='/account'
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path='/become-buyer'
          element={
            <PrivateRoute>
              <BecomeBuyer />
            </PrivateRoute>
          }
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
