import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { AUTH_USER_SUCCESS } from '../Helpers/Constant';

import reducer from '../Reducers/AuthReducer';

const initialState = {
  user: {},
};

const AuthContext = createContext();

const AuthContextProvidor = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const user = localStorage.getItem('__u_o');
    if (user) {
      const nowUser = JSON.parse(window.atob(user));

      dispatch({ type: AUTH_USER_SUCCESS, payload: nowUser });
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ ...state, dispatch, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  return useContext(AuthContext);
};

export { AuthContextProvidor, useAuthContext };
