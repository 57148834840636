import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { url } from '../../../Helpers/Constant';

import { useNavigate } from 'react-router-dom';

const NewPassword = ({ token, phone }) => {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [passwordType, setPasswordType] = useState('password');

  const navigate = useNavigate();

  const handelSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (password1.length && password2.length > 5) {
      if (password1 === password2) {
        fetch(`${url}/out/api/auth/buyer/forgot/password`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            token,
            phone,
            password: password2,
          }),
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.success) {
              alert(result.message);
              navigate('/login');
            }
          });
      } else {
        setError('password does not match');
        setTimeout(() => {
          setError('');
        }, 5000);
        setIsLoading(false);
      }
    } else {
      setError('password must be at least 6 characters');
      setTimeout(() => {
        setError('');
      }, 5000);
      setIsLoading(false);
    }
  };
  return (
    <Container>
      <Row>
        <Col md={4} className='mx-auto'>
          <div className=' text-center sign-up-form'>
            <div className='mb-4'>
              <h5 className='fw-bold'>Set new password</h5>
            </div>
            <form action='' onSubmit={handelSubmit}>
              <div>
                <p className='fw-bold text-start '>Enter New Password</p>
                <input
                  onChange={(e) => setPassword1(e.target.value)}
                  required
                  placeholder='Enter Your Number'
                  className='my-1 w-100 px-2 py-2 sign-up-input'
                  type={passwordType}
                />
              </div>
              <div className='mt-3'>
                <p className='fw-bold text-start'> Retype Password</p>
                <input
                  onChange={(e) => setPassword2(e.target.value)}
                  required
                  placeholder='Enter Your Password'
                  className='my-1 w-100 px-2 py-2 sign-up-input'
                  type={passwordType}
                />
              </div>

              <div className='text-start ps-2 mt-2'>
                <input
                  type='checkbox'
                  onClick={() => {
                    passwordType === 'password'
                      ? setPasswordType('text')
                      : setPasswordType('password');
                  }}
                />
                <span
                  style={{
                    marginLeft: '8px',
                  }}
                >
                  Show Password
                </span>
              </div>
              {error && (
                <div className='mt-2 text-center'>
                  <small className='text-danger fw-bold'>{error}</small>
                </div>
              )}
              {isLoading ? (
                <div className='loading-btn my-2'>
                  <div>
                    <input
                      type='submit'
                      className='my-2 submit-button'
                      disabled
                      value='Submit...'
                    />
                  </div>
                </div>
              ) : (
                <input
                  className='mb-1 mt-2 submit-button'
                  type='submit'
                  value='Submit'
                />
              )}
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NewPassword;
