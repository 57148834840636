import React, { useEffect, useState } from 'react';
import { Row, Spinner } from 'react-bootstrap';
import { url } from '../Helpers/Constant';
import ShowFreelancing from '../ShowFreelancing/ShowFreelancing';

const AllServicesPage = () => {
  const [allGig, setAllGig] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`${url}/out/api/gig/get/approved/all?limit=30&skip=0`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setAllGig(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  }, []);
  return (
    <div>
      {loading ? (
        <div className='text-center  mt-5'>
          <Spinner animation='border' style={{ color: '#e9313a' }} />
        </div>
      ) : (
        <div>
          {allGig?.length ? (
            <div>
              <Row md={3} lg={3} xs={1}>
                {allGig.map((gig) => (
                  <ShowFreelancing key={gig.gig_id} gig={gig} />
                ))}
              </Row>
            </div>
          ) : (
            <div>
              <p className='text-center mt-5 fw-bold fs-4'>No Gig Available </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AllServicesPage;
