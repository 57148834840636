import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink, Route, Routes } from 'react-router-dom';
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io';
import FreelancingCategory from '../FreelancingCategory/FreelancingCategory';
import AllServicesPage from '../AllServicesPage/AllServicesPage';
import './AllServices.css';
import { Header } from '../Header/Header';
import { url } from '../Helpers/Constant';
import { ServicesPhone } from './ServicesPhone';

const AllServices = () => {
  const [category, setCategory] = useState([]);
  useEffect(() => {
    fetch(`${url}/out/api/seller/get/all/skill-categories`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setCategory(data.data);
        }
      });
  }, []);
  useEffect(() => {
    document.title = 'All-Services | Freelancing';
  }, []);
  return (
    <div>
      <Header />
      <Container>
        <div className='my-3'>
          <h2>Freelancing All gig</h2>
        </div>
        <Row className='my-2 mb-5 '>
          <Col md={3}>
            <div className='category-wrapper '>
              <h3 className='mb-2 d-none d-md-block'>Gig Category</h3>
              <div className='d-none d-md-block  pt-2'>
                <ul className='ps-0 categories  '>
                  {/* {category.map((sCategory) => {
                    const { id, name } = sCategory;

                    return (
                      <li key={id}>
                        <NavLink
                          to={`/allservices/allcategory/${name}`}
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          {name}
                        </NavLink>
                      </li>
                    );
                  })} */}

                  <li>
                    <div>
                      <div
                        onClick={() => {
                          category === 'Application Development'
                            ? setCategory('')
                            : setCategory('Application Development');
                        }}
                      >
                        <div className='d-flex justify-content-between'>
                          <div className='pointer fw-bold'>
                            Application Development
                          </div>
                          <div>
                            {category === 'Application Development' ? (
                              <IoIosArrowDown className='pointer' />
                            ) : (
                              <IoIosArrowForward className='pointer' />
                            )}
                          </div>
                        </div>
                      </div>
                      {category === 'Application Development' && (
                        <ul>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/mobileapps'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Mobile Apps
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/webapps'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Web Apps
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/desktopapplication'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Desktop Application
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/gamedevelopment'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Game Development
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/webprogramming'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Web programming
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/wordPress'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              WordPress
                            </NavLink>
                          </li>
                        </ul>
                      )}
                    </div>
                  </li>
                  <li>
                    <div>
                      <div
                        onClick={() => {
                          category === 'GraphicsDesign'
                            ? setCategory('')
                            : setCategory('GraphicsDesign');
                        }}
                      >
                        <div className='d-flex justify-content-between'>
                          <div className='pointer fw-bold'>
                            Graphics & Design
                          </div>
                          <div>
                            {category === 'GraphicsDesign' ? (
                              <IoIosArrowDown className='pointer' />
                            ) : (
                              <IoIosArrowForward className='pointer' />
                            )}
                          </div>
                        </div>
                      </div>
                      {category === 'GraphicsDesign' && (
                        <ul>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/logodesign'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Logo Design
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/websitedesign'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Website Design
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/appdesign'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              App Design
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/uxdesign'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Ux design
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/icondesign'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Icon Design
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/bannerdesign'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Banner Design
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/templatedesign'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Template Design
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/fashiondesign'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Fashion Design
                            </NavLink>
                          </li>
                        </ul>
                      )}
                    </div>
                  </li>
                  <li>
                    <div>
                      <div
                        onClick={() => {
                          category === 'Explore Digital Marketing'
                            ? setCategory('')
                            : setCategory('Explore Digital Marketing');
                        }}
                      >
                        <div className='d-flex justify-content-between'>
                          <div className='pointer fw-bold'>
                            Explore Digital Marketing
                          </div>
                          <div>
                            {category === 'Explore Digital Marketing' ? (
                              <IoIosArrowDown className='pointer' />
                            ) : (
                              <IoIosArrowForward className='pointer' />
                            )}
                          </div>
                        </div>
                      </div>
                      {category === 'Explore Digital Marketing' && (
                        <ul>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/seo'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Search Engine Optimization (SEO)
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/localseo'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Local SEO
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/socialmediamarketing'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Social Media Marketing
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/youtubechannelmarketing'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Youtube Channel Marketing
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/youtubevideomarketing'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Youtube Video Marketing
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/guestposting'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Guest Posting
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/emailmarketing'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Email Marketing
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/textmessagemarketing'
                              className={({ isActive }) =>
                                isActive ? 'active-category fs-5' : ''
                              }
                            >
                              Text Message Marketing
                            </NavLink>
                          </li>
                        </ul>
                      )}
                    </div>
                  </li>
                  <li>
                    <div>
                      <div
                        onClick={() => {
                          category === 'Writing Translation'
                            ? setCategory('')
                            : setCategory('Writing Translation');
                        }}
                      >
                        <div className='d-flex justify-content-between'>
                          <div className='pointer fw-bold'>
                            Writing & Translation
                          </div>
                          <div>
                            {category === 'Writing Translation' ? (
                              <IoIosArrowDown className='pointer' />
                            ) : (
                              <IoIosArrowForward className='pointer' />
                            )}
                          </div>
                        </div>
                      </div>
                      {category === 'Writing Translation' && (
                        <ul>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/articlesbolgpost'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Articles and Blog Posts
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/websitecontent'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Website Content
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/bookebook'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Book and eBook writing
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/speechwriting'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Speech writing
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/scriptwriting'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Script Writing
                            </NavLink>
                          </li>

                          <li>
                            <NavLink
                              to='/allservices/allcategory/podcastwriting'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Podcast Writing
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/resumewriting'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Resume Writing
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/coverletters'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Cover letters
                            </NavLink>
                          </li>
                        </ul>
                      )}
                    </div>
                  </li>
                  <li>
                    <div>
                      <div
                        onClick={() => {
                          category === 'Video Animation'
                            ? setCategory('')
                            : setCategory('Video Animation');
                        }}
                      >
                        <div className='d-flex justify-content-between'>
                          <div className='pointer fw-bold'>
                            Explore Video & Animation
                          </div>
                          <div>
                            {category === 'Video Animation' ? (
                              <IoIosArrowDown className='pointer' />
                            ) : (
                              <IoIosArrowForward className='pointer' />
                            )}
                          </div>
                        </div>
                      </div>
                      {category === 'Video Animation' && (
                        <ul>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/shortvideoads'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Short Video Ads
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/socialmediavideos'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Social media Videos
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/lyricmusicvideos'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Lyric and music videos
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/slideshowvideos'
                              className={({ isActive }) =>
                                isActive ? 'active-category ' : ''
                              }
                            >
                              Slideshow videos
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/corporatevideos'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Corporate videos
                            </NavLink>
                          </li>

                          <li>
                            <NavLink
                              to='/allservices/allcategory/unboxingvideos'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Unboxing videos
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/animationvideos'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Animation videos
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to='/allservices/allcategory/logoanimation'
                              className={({ isActive }) =>
                                isActive ? 'active-category' : ''
                              }
                            >
                              Logo animation
                            </NavLink>
                          </li>
                        </ul>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {/* category for phone  */}

            <div className='d-md-none d-block'>
              <ServicesPhone category={category} setCategory={setCategory} />
            </div>
          </Col>
          <Col md={9} className='show-product'>
            <Routes>
              <Route path='/' element={<AllServicesPage />}></Route>
              {/* {category.map((sCategory) => {
                const { id, name } = sCategory;
                return (
                  <Route
                    key={id}
                    path={`/${name}`}
                    element={<FreelancingCategory category={`${name}`} />}
                  />
                );
              })} */}

              <Route
                path='/mobileapps'
                element={<FreelancingCategory category='apps devlopment' />}
              ></Route>
              <Route
                path='/webapps'
                element={<FreelancingCategory category='web apps' />}
              ></Route>
              <Route
                path='/desktopapplication'
                element={<FreelancingCategory category='desktop application' />}
              ></Route>
              <Route
                path='/gamedevelopment'
                element={<FreelancingCategory category='game development' />}
              ></Route>
              <Route
                path='/webprogramming'
                element={<FreelancingCategory category='web programming' />}
              ></Route>
              <Route
                path='/wordpress'
                element={<FreelancingCategory category='wordpress' />}
              ></Route>
              {/* Graphics & Design */}
              <Route
                path='/logodesign'
                element={<FreelancingCategory category='logo design' />}
              />
              <Route
                path='/websitedesign'
                element={<FreelancingCategory category='web design' />}
              />
              <Route
                path='/appdesign'
                element={<FreelancingCategory category='app design' />}
              />
              <Route
                path='/uxdesign'
                element={<FreelancingCategory category='ux design' />}
              />
              <Route
                path='/icondesign'
                element={<FreelancingCategory category='icon design' />}
              />
              <Route
                path='/bannerdesign'
                element={<FreelancingCategory category='banner design' />}
              />
              <Route
                path='/templatedesign'
                element={<FreelancingCategory category='tamplate design' />}
              />
              <Route
                path='/fashiondesign'
                element={<FreelancingCategory category='fashion design' />}
              />
              {/* Explore Digital Marketing */}
              <Route
                path='/seo'
                element={<FreelancingCategory category='seo' />}
              />
              <Route
                path='/localseo'
                element={<FreelancingCategory category='local seo' />}
              />
              <Route
                path='/socialmediamarketing'
                element={
                  <FreelancingCategory category=' Social Media Marketing' />
                }
              />
              <Route
                path='/youtubechannelmarketing'
                element={
                  <FreelancingCategory category='youtube channel marketing' />
                }
              />
              <Route
                path='/youtubevideomarketing'
                element={
                  <FreelancingCategory category='youtube video marketing' />
                }
              />
              <Route
                path='/guestposting'
                element={<FreelancingCategory category='guest posting' />}
              />
              <Route
                path='/emailmarketing'
                element={<FreelancingCategory category='email marketing' />}
              />
              <Route
                path='/textmessagemarketing'
                element={
                  <FreelancingCategory category='text message marketing' />
                }
              />
              {/* Writing & Translation */}
              <Route
                path='/articlesbolgpost'
                element={<FreelancingCategory category='articles bolg post' />}
              />
              <Route
                path='/websitecontent'
                element={<FreelancingCategory category='website content' />}
              />
              <Route
                path='/bookebook'
                element={<FreelancingCategory category='book ebook' />}
              />
              <Route
                path='/speechwriting'
                element={<FreelancingCategory category='speech writing' />}
              />
              <Route
                path='/scriptwriting'
                element={<FreelancingCategory category='script writing' />}
              />
              <Route
                path='/podcastwriting'
                element={<FreelancingCategory category='podcast writing' />}
              />
              <Route
                path='/resumewriting'
                element={<FreelancingCategory category='resume writing' />}
              />
              <Route
                path='/coverletters'
                element={<FreelancingCategory category='cover letters' />}
              />
              {/* Explore Video & Animation */}
              <Route
                path='/shortvideoads'
                element={<FreelancingCategory category='short video ads' />}
              />
              <Route
                path='/socialmediavideos'
                element={<FreelancingCategory category='social media videos' />}
              />
              <Route
                path='/lyricmusicvideos'
                element={<FreelancingCategory category='lyric music videos' />}
              />
              <Route
                path='/slideshowvideos'
                element={<FreelancingCategory category='slideshow videos' />}
              />
              <Route
                path='/corporatevideos'
                element={<FreelancingCategory category='corporate videos' />}
              />
              <Route
                path='/unboxingvideos'
                element={<FreelancingCategory category='unboxing videos' />}
              />
              <Route
                path='/animationvideos'
                element={<FreelancingCategory category='animation videos' />}
              />
              <Route
                path='/logoanimation'
                element={<FreelancingCategory category='logo animation' />}
              />
            </Routes>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AllServices;
