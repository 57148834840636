import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { districts } from '../AuthenticationPage/DistrictsData/districts';
import { useAuthContext } from '../context/AuthContext';
import { url, USER_PROFILE_UPDATE } from '../Helpers/Constant';

const ProfileEditModal = ({ show, handleClose }) => {
  const { dispatch: AuthDispatch, user } = useAuthContext();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const [divisionID, setDivisionID] = useState(user.division);
  const [profileImg, setProfileImg] = useState('');

  const onSubmit = (inputsData) => {
    setIsLoading(true);

    const formData = new FormData();
    Object.keys(inputsData).forEach((item) => {
      if (inputsData[item]) {
        formData.append(item, inputsData[item]);
      }
    });
    if (profileImg) formData.append('photo', profileImg);

    if (inputsData.phone.length === 11 && inputsData.phone.startsWith('01')) {
      if (
        inputsData.account_number.length === 11 &&
        inputsData.account_number.startsWith('01')
      ) {
        fetch(`${url}/out/api/buyer/update/profile/${user?.id}`, {
          method: 'PUT',
          body: formData,
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              if (data.data.photo) {
                inputsData['photo'] = data.data.photo;
              }
              AuthDispatch({
                type: USER_PROFILE_UPDATE,
                payload: { ...inputsData },
              });

              const consData = {
                ...inputsData,
                photo: data.data.photo ? data.data.photo : user.photo,
                id: user.id,
                status: user.status,
                customer_id: null || user.customer_id,
                city: inputsData.city ? inputsData.city : user.city,
              };

              localStorage.setItem(
                '__u_o',
                window.btoa(JSON.stringify(consData))
              );

              alert(data.message);
              handleClose();
              setIsLoading(false);
            } else {
              setError(data.message);
              setTimeout(() => {
                setError('');
              }, 5000);
              setIsLoading(false);
            }
          });
      } else {
        setError('Invalid Bank Account Number');
        setIsLoading(false);
        setTimeout(() => {
          setError('');
        }, 5000);
      }
    } else {
      setError('Invalid Phone Number');
      setIsLoading(false);
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile Inforamtion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action='' onSubmit={handleSubmit(onSubmit)}>
            <div className='d-flex align-items-center gap-5 ps-5'>
              <div className='position-relative  text-success '>
                <label htmlFor='image' className='pointer'>
                  <div className='service-up-image-input'>
                    <div className='mx-3 mt-2 '>
                      <img
                        className='mx-2'
                        src='/assets/freelancing/AddImage.png'
                        alt=''
                      />
                      <h6>Add new</h6>
                    </div>
                  </div>
                  <input
                    className='d-none'
                    type='file'
                    id='image'
                    accept='.jpg, .png, .jpeg'
                    onChange={(e) =>
                      e.target.files[0] && setProfileImg(e.target.files[0])
                    }
                  />
                </label>
              </div>

              <div>
                {profileImg ? (
                  <div>
                    <img
                      width={130}
                      height={100}
                      src={URL.createObjectURL(profileImg)}
                      alt=''
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      width={130}
                      height={100}
                      src={`${url}/get/image/freelancing_buyer_files/${user.photo}`}
                      alt=''
                    />
                  </div>
                )}
              </div>
            </div>

            {/* image  */}
            <div>
              <h6 className='fw-bold text-start '>Enter Your Name</h6>
              <input
                {...register('name')}
                defaultValue={user.name}
                placeholder='Enter Your Name'
                className='my-1 w-100 px-2 py-2 sign-up-input'
                type='text'
              />
            </div>
            <div className='py-2'>
              <h6 className='fw-bold text-start '>Enter Your Phone Number</h6>
              <input
                {...register('phone')}
                defaultValue={user.phone}
                placeholder='Enter Your Phone Number'
                className='my-1 w-100 px-2 py-2 sign-up-input'
                type='number'
              />
            </div>
            <div>
              <h6 className='fw-bold text-start '>Enter Your Email</h6>
              <input
                {...register('email')}
                defaultValue={user.email}
                placeholder=' Enter Your Email'
                className='my-1 w-100 px-2 py-2 sign-up-input'
                type='email'
              />
            </div>
            <div>
              <div className='mt-2'>
                <h6 className='fw-bold text-start pb-1'>
                  Select Your Division
                </h6>
                <select
                  onClick={(e) => setDivisionID(e.target.value)}
                  defaultValue={user.division}
                  className='sign-up-input w-100'
                  {...register('division')}
                >
                  <option value=''>Select Division</option>
                  <option value='Barisal'>Barisal</option>
                  <option value='Chittagong'>Chittagong</option>
                  <option value='Dhaka'>Dhaka</option>
                  <option value='Khulna'>Khulna</option>
                  <option value='Mymensingh'>Mymensingh</option>
                  <option value='Rajshahi'>Rajshahi</option>
                  <option value='Rangpur'>Rangpur</option>
                  <option value='Rajshahi'>Sylhet</option>
                </select>
              </div>

              <div className='mt-2'>
                <h6 className='fw-bold text-start pb-1'>Select Your city</h6>

                <select
                  className='sign-up-input w-100'
                  {...register('city')}
                  defaultValue={user.city}
                >
                  {districts.map(
                    (dis) =>
                      divisionID === dis.division_name && (
                        <option value={dis.name} key={dis.id}>
                          {dis.name}
                        </option>
                      )
                  )}
                </select>
              </div>
            </div>
            <div className='pt-2'>
              <h6 className='fw-bold text-start '>Enter Your Address</h6>
              <input
                {...register('address')}
                defaultValue={user.address}
                placeholder='Enter  Your Address'
                className='my-1 w-100 px-2 py-2 sign-up-input'
                type='text'
              />
            </div>
            <div className='mt-2'>
              <h6 className='fw-bold text-start '>Enter Your Post Code</h6>
              <input
                {...register('post_code')}
                defaultValue={user.post_code}
                placeholder=' Enter Your Post Code'
                className='my-1 w-100 px-2 py-2 sign-up-input'
                type='number'
              />
            </div>

            <div className='mt-2'>
              <h6 className='fw-bold text-start pb-1'>Select Bank Name</h6>
              <select
                className='sign-up-input w-100'
                {...register('bank_name')}
                defaultValue={user.bank_name}
              >
                <option value=''>Select Bank</option>
                <option value='bkash'>Bkash</option>
                <option value='nagad'>Nagad</option>
                <option value='upay'>Upay</option>
              </select>
            </div>

            <div className='mt-2'>
              <h6 className='fw-bold text-start '>Enter Bank Account Number</h6>
              <input
                {...register('account_number')}
                defaultValue={user.account_number}
                placeholder=' Enter Bank Account Number'
                className='my-1 w-100 px-2 py-2 sign-up-input'
                type='text'
              />
            </div>

            {error && (
              <div className='mt-2 mb-1 text-center'>
                <small className='text-danger fw-bold'>{error}</small>
              </div>
            )}

            {isLoading ? (
              <div className='loading-btn my-2 '>
                <div className='text-center'>
                  <input
                    type='submit'
                    className='my-2 submit-button'
                    disabled
                    value='Submit...'
                  />
                </div>
              </div>
            ) : (
              <div className='text-center'>
                <input
                  className='my-2 submit-button'
                  type='submit'
                  value='Submit'
                />
              </div>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProfileEditModal;
