import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuthContext } from '../context/AuthContext';
import { url } from '../Helpers/Constant';

const ContactSellerModel = ({ sGig }) => {
  const { user } = useAuthContext();
  const { seller_name, seller_id, seller_photo } = sGig;
  const { handleSubmit } = useForm();
  const [buyersms, setBuyerSms] = useState('');
  const [conv, setConv] = useState({});
  const [success, setsuccess] = useState('');

  // modal onclick handel
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (seller_id) {
      fetch(`${url}/out/api/convo/get/${user.id}/${seller_id}`)
        .then((res) => res.json())
        .then((data) => {
          setConv(data.data);
        });
    }
  }, [user.id, seller_id]);

  const onSubmit = async (data) => {
    data.buyer_id = user.id;
    data.seller_id = seller_id;

    const res = await fetch(`${url}/out/api/convo/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const result = await res.json();
    if (result.success) {
      const formData = new FormData();
      formData.append('reciever_id', seller_id);
      formData.append('sender_id', user.id);
      formData.append('message', buyersms);

      const sendMsg = await fetch(
        `${url}/out/api/convo/send/message/${result.data.id}`,
        {
          method: 'POST',
          body: formData,
        }
      );
      const data = await sendMsg.json();
      if (data.success) {
        setsuccess('Message sent successfylly');
        setBuyerSms('');
        setTimeout(() => {
          setsuccess('');
        }, 7000);
      }
    }
  };

  return (
    <div>
      <div className='contact-seller mt-5 mx-auto text-center py-2'>
        {conv?.id ? (
          <Link className=' text-decoration-none text-black' to='/message'>
            <h5>Contact Seller</h5>
          </Link>
        ) : (
          <h5 onClick={handleShow}>Contact Seller</h5>
        )}
      </div>

      <Modal show={show} onHide={handleClose} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className='d-flex align-items-center gap-3'>
              <div>
                <img
                  className='rounded-circle'
                  width={40}
                  height={40}
                  src={`${url}/get/image/freelancing_seller_files/${seller_photo}`}
                  alt=''
                />
              </div>
              <div>
                <h5>
                  Message <span className='text-success'>{seller_name}</span>
                </h5>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)} className='text-end'>
            <textarea
              required
              className='buyer-sms-send w-100'
              onChange={(e) => setBuyerSms(e.target.value)}
              placeholder={`Ask ${seller_name} a question `}
              value={buyersms}
            />
            {success ? (
              <p className='my-2 text-center text-success'>{success}</p>
            ) : (
              <input type='submit' value='Send Message' />
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Link to='/message'>
            <Button>Open Conversation</Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ContactSellerModel;
