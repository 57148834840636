import React from 'react';
import { useAuthContext } from '../../context/AuthContext';
import { Navigate } from 'react-router-dom';
import ShowSpinner from '../../ShowSpinner/ShowSpinner';

const PrivateRoute = ({ children }) => {
  const { user, isLoading } = useAuthContext();

  if (isLoading) {
    return <ShowSpinner />;
  } else {
    return user.phone ? children : <Navigate to='/login' />;
  }
};

export default PrivateRoute;
