import React from 'react';
import { Header } from '../Header/Header';
import MainBodyWrapper from '../MainBodyWrapper/MainBodyWrapper';

const HomeWrapper = () => {
  return (
    <div>
      <Header />
      <MainBodyWrapper />
    </div>
  );
};

export default HomeWrapper;
