import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { url } from '../../Helpers/Constant';

export const Conversation = ({ conv }) => {
  const { seller_name, seller_photo, seller_id } = conv;
  const location = useLocation();

  const path = location.pathname.slice(9);

  useEffect(() => {
    document.title = `${seller_name} | Freelancing`;
  }, [seller_name]);

  return (
    <div className={Number(path) === seller_id ? 'active-conv' : 'single-conv'}>
      <Link to={`${seller_id}`} className=' text-decoration-none'>
        <div className='d-flex ps-4 align-items-center gap-2 '>
          <img
            className='rounded-circle'
            width={40}
            height={40}
            src={`${url}/get/image/freelancing_seller_files/${seller_photo}`}
            alt=''
          />
          <h6 className='fw-bold text-black'>{seller_name}</h6>
        </div>
      </Link>
    </div>
  );
};
