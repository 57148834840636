import React from 'react';

export const NotFound = () => {
  return (
    <div
      className='text-center '
      style={{
        backgroundColor: '#ebebeb',
        height: '100vh',
      }}
    >
      <div className='pt-5'>
        <img src='/assets/notfound.png' alt='' />
      </div>
    </div>
  );
};
