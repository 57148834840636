import React, { useState } from 'react';
import { Header } from '../../Header/Header';
import './Login.css';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import { AUTH_USER_SUCCESS, url } from '../../Helpers/Constant';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';

export const Login = () => {
  const { dispatch: AuthDispatch } = useAuthContext();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [passwordtype, setPasswordtype] = useState('password');
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    setIsLoading(true);
    if (data.phone.startsWith('01') && data.phone.length === 11) {
      fetch(`${url}/out/api/auth/buyer/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            AuthDispatch({ type: AUTH_USER_SUCCESS, payload: data.data });
            localStorage.setItem(
              '__u_o',
              window.btoa(JSON.stringify(data.data))
            );
            navigate('/');
            setIsLoading(false);
          } else {
            setError(data.message);
            setIsLoading(false);
            setTimeout(() => {
              setError('');
            }, 5000);
          }
        });
    } else {
      setError('Invalid phone Number');
      setIsLoading(false);
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };
  // className = 'login-wrapper';
  return (
    <div>
      <Header />

      <div>
        <Container>
          <Row>
            <Col md={4} className='mx-auto'>
              <div className='my-5 text-center sign-up-form'>
                <form action='' onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <h6 className='fw-bold text-start '>Enter Your Number</h6>
                    <input
                      {...register('phone')}
                      // onClick={() => setError('')}
                      required
                      placeholder='Enter Your Number'
                      className='my-1 w-100 px-2 py-2 sign-up-input'
                      type='number'
                    />
                  </div>
                  <div className='mt-3'>
                    <h6 className='fw-bold text-start'>Enter Your Password</h6>
                    <input
                      {...register('password')}
                      // onClick={() => setError('')}
                      required
                      placeholder='Enter Your Password'
                      className='my-1 w-100 px-2 py-2 sign-up-input'
                      type={passwordtype}
                    />
                  </div>
                  <div className='text-start ps-1'>
                    <input
                      type='checkbox'
                      name=''
                      id=''
                      onClick={(e) => {
                        if (e.target.checked) {
                          setPasswordtype('text');
                        } else {
                          setPasswordtype('password');
                        }
                      }}
                    />
                    <span className='ps-1'>Show password</span>
                  </div>
                  {error && (
                    <div className='mt-2 text-center'>
                      <small className='text-danger fw-bold'>{error}</small>
                    </div>
                  )}
                  {isLoading ? (
                    <div className='loading-btn my-2'>
                      <div>
                        {/* <ShowSpinner /> */}
                        <input
                          type='submit'
                          className='my-2 submit-button'
                          disabled
                          value='Submit...'
                        />
                      </div>
                    </div>
                  ) : (
                    <input
                      className='mb-1 mt-2 submit-button'
                      type='submit'
                      value='Submit'
                    />
                  )}

                  <div className='mt-2'>
                    <Link to='/forgetpassword' className='text-decoration-none'>
                      Forget Password?
                    </Link>
                  </div>
                  <hr />

                  <div className='d-flex align-item-center  justify-content-center'>
                    <p>New to Freelancing?</p>{' '}
                    <Link to='/join' className='text-decoration-none ps-1'>
                      Join
                    </Link>
                  </div>

                  {/* <div className='text-center mt-3'>
                    <Link
                      className=' text-decoration-none'
                      to='/forgot-password'
                    >
                      'Forgot password' ?
                    </Link>
                  </div> */}
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
