import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';

const ShowAllOrders = ({ order }) => {
  // const { user } = useAuthContext();
  const { gig_title, offer_price, seller_name, status, id, gig_id } = order;

  // , setAllOrders, allorders
  // const CancelOrder = (id) => {
  //   const data = {
  //     status: 'Rejected',
  //     statusChangedBy: 'buyer',
  //   };
  //   const confirm = window.confirm(
  //     'Are you sure you want to delete this order?'
  //   );
  //   if (confirm) {
  //     fetch(`${url}/out/api/gig-order/update/status/${id}`, {
  //       method: 'PUT',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify(data),
  //     })
  //       .then((res) => res.json())
  //       .then((data) => {
  //         if (data.success) {
  //           setAllOrders((allorders) =>
  //             allorders.filter((cancelOrder) => cancelOrder.id !== id)
  //           );
  //           window.alert(data.message);
  //         }
  //       });
  //   }
  // };
  return (
    <div>
      {' '}
      <Accordion.Item eventKey={id} className='mb-2'>
        <Accordion.Header>
          <div>
            <p>{gig_title}</p>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className='d-flex justify-content-between'>
            <div>
              <h6>Seller Name : {seller_name} </h6>
              <h6 className='my-1'>Price : {offer_price} tk </h6>
              <h6>Status : {status} </h6>

              <div className='mt-3'>
                <Link
                  className=' text-decoration-none'
                  to={`/allservices/${gig_id}`}
                >
                  Go this gig
                </Link>
              </div>
            </div>

            {/* <div>
              <p
                className='text-white border px-2 py-1 bg-danger rounded pointer'
                onClick={() => CancelOrder(id)}
              >
                Cancel order
              </p>
            </div> */}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </div>
  );
};

export default ShowAllOrders;
