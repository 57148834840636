import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { Header } from '../Header/Header';
import { url } from '../Helpers/Constant';
import ShowFreelancing from '../ShowFreelancing/ShowFreelancing';

const SearchPage = () => {
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = `${search} |Freelancing`;
  }, [search]);

  useEffect(() => {
    setLoading(true);
    fetch(`${url}/out/api/gig/get/search/${search}?limit=10&skip=0`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setSearchData(data.data);
          setLoading(false);
        }
      });
  }, [search]);

  return (
    <>
      <Header />

      <div>
        <div className='text-center pb-5 mt-5'>
          <h1>
            Search Result for{' '}
            <span style={{ color: '#1c4594' }}>"{search}"</span>
          </h1>
        </div>

        <Row className='text-center'>
          <Col>
            <div className='show-search-result'>
              {loading ? (
                <div className='text-center '>
                  <Spinner animation='border' style={{ color: '#e9313a' }} />
                </div>
              ) : (
                <div>
                  {searchData?.length ? (
                    <div>
                      <Row md={3} lg={4} xs={1}>
                        {searchData?.map((gig) => (
                          <ShowFreelancing gig={gig} key={gig.gig_id} />
                        ))}
                      </Row>
                    </div>
                  ) : (
                    <div>
                      <h4 className='my-5 text-center fs-4 '>
                        No Result Found
                      </h4>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SearchPage;
