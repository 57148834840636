import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import './MainBodyWrapper.css';
import { url } from '../Helpers/Constant';
import ShowFreelancing from '../ShowFreelancing/ShowFreelancing';
import FreelancingHomeCategory from './FreelancingHomeCategory';

const MainBodyWrapper = () => {
  const [sliceGig, setSlceGig] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = 'Freelancing | Ontheway';
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(`${url}/out/api/gig/get/approved/all?limit=30&skip=0`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setSlceGig(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  }, []);

  return (
    <div>
      <Container>
        <div>
          <img className='img-fluid' src='/assets/outsourcing.png' alt='' />
        </div>
        <div>
          <div>
            <h3 className=' fw-bold mb-3 '>Most popular Gigs</h3>
            {loading ? (
              <div className='text-center '>
                <Spinner animation='border' style={{ color: '#e9313a' }} />
              </div>
            ) : (
              <div>
                {sliceGig?.length ? (
                  <div>
                    <Row md={3} lg={4} xs={1}>
                      {sliceGig?.map((gig) => (
                        <ShowFreelancing gig={gig} key={gig.gig_id} />
                      ))}
                    </Row>
                  </div>
                ) : (
                  <div>
                    <p className='my-5 text-center fs-4 text-secondary'>
                      Popular Gig Coming Soon
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className='mt-4'>
            <h3 className='mb-3 fw-bold'>Explore the services</h3>
            <hr className='mb-5' />
            <FreelancingHomeCategory />
          </div>

          {/* some description and details */}
          <div className='outsourcing-div-wrapper pb-3 mb-5 px-3 mt-5'>
            <h3 className=' fw-bold fs-3 pt-3'>Need something done?</h3>

            {/* text div */}
            <div className='d-flex gap-4 my-5  '>
              <Row xs={1} md={3}>
                <Col className='mt-3 mt-md-0'>
                  <div className='outsourcing-text-div'>
                    <div className='mx-3'>
                      <div className='mb-3 text-center'>
                        <h6 className='fw-bold fs-5 pt-2'>Post a job</h6>
                      </div>
                      <p className='text-des pb-2'>
                        It’s free and straightforward to post employment. merely
                        fill in a very title, description and budget and
                        competitive bids come back among minute
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className='mt-3 mt-md-0'>
                  <div className='outsourcing-text-div'>
                    <div className='mx-3'>
                      <div className='mb-3 text-center'>
                        <h6 className='fw-bold fs-5 pt-2'>
                          Choose freelancers
                        </h6>
                      </div>
                      <p className='text-des pb-2'>
                        No job is just too huge or too little. we have
                        freelancers for jobs of any size or budget. No job is
                        just too complicated. we are able to get the picture
                        done!
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className='mt-3 mt-md-0'>
                  <div className='outsourcing-text-div'>
                    <div className='mx-3'>
                      <div className='mb-3 text-center'>
                        <h6 className='fw-bold fs-5 pt-2'>
                          We’re here to help
                        </h6>
                      </div>
                      <p className='text-des pb-2'>
                        Our gifted team of recruiters will assist you realize
                        the most effective freelancer for the duty and our
                        technical co-pilots will even manage the project for
                        you.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MainBodyWrapper;
