import React from 'react';
import { Col } from 'react-bootstrap';
import { MdFavorite } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { url } from '../Helpers/Constant';
import './ShowFreelancing.css';

const ShowFreelancing = ({ gig }) => {
  const {
    seller_name,
    name,
    gig_id,
    ft_img,
    title,
    photo,
    seller_photo,
    price,
    id,
  } = gig;

  return (
    <Col className='mb-4'>
      {
        <Link
          to={`/allservices/${gig_id ? gig_id : id}`}
          className='text-decoration-none'
        >
          {
            <div className='gig-wrapper pointer'>
              <div>
                {/* gig-image */}
                <div className='gig-img-wrapper'>
                  <img
                    loading='lazy'
                    style={{ objectFit: 'contain' }}
                    className='w-100'
                    height={200}
                    src={`${url}/get/image/freelancing_gig_files/${ft_img}`}
                    alt=''
                  />
                </div>
                <div
                  className='p-2'
                  style={{ height: '130px', backgroundColor: '#fff' }}
                >
                  <div className='d-flex align-items-center gap-2'>
                    <div>
                      <img
                        loading='lazy'
                        width='40px'
                        height='40px'
                        className='rounded-circle'
                        src={`${url}/get/image/freelancing_seller_files/${
                          photo || seller_photo
                        }`}
                        alt=''
                      />
                    </div>
                    <div>
                      <h6 className=' fw-bold'>{name ? name : seller_name}</h6>
                    </div>
                  </div>
                  <div className='text-black mt-3 '>
                    <h5 className='gig-details'>{title} </h5>
                  </div>
                </div>
                <div className='d-flex align-items-center justify-content-between gig-bottom p-2'>
                  <div>
                    <small>STARTING AT</small>
                    <p className='fw-bold'>BDT &#2547; {price}</p>
                  </div>
                  <div>
                    <MdFavorite className='fs-3' />
                  </div>
                </div>
              </div>
            </div>
          }
        </Link>
      }
    </Col>
  );
};

export default ShowFreelancing;
