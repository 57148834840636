import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import ChatSection from '../ChatSection/ChatSection';
import { useAuthContext } from '../../context/AuthContext';
import { Conversation } from '../Conversation/Conversation';
import { Header } from '../../Header/Header';
import { url } from '../../Helpers/Constant';
import './Message.css';
import { NoSellerChat } from './NoSellerChat';

const Message = () => {
  const { user } = useAuthContext();
  const [allConv, setAllConv] = useState([]);

  const [loading, setLoading] = useState(false);

  // get all converstation
  useEffect(() => {
    setLoading(true);
    fetch(`${url}/out/api/buyer/get/all/convo/${user.id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setAllConv(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  }, [user.id]);
  useEffect(() => {
    document.title = 'Message | Freelancing ';
  }, []);

  return (
    <>
      <Header />
      <div className='message-container'>
        <Container className='mb-5'>
          <Row className=' mt-4 g-2' style={{ backgroundColor: '#ffffff' }}>
            <Col
              md={4}
              style={{
                border: '2px solid #e7e7e7',
                borderBottom: 'none',
                borderRight: 'none',
              }}
            >
              <div className='mt-2 '>
                <div className=' text-center '>
                  <h4 style={{ color: '#62646a' }}>All Conversations </h4>
                  <hr className='mx-5' />
                </div>
              </div>
              <div>
                {loading ? (
                  <div className='text-center '>
                    <Spinner animation='border' style={{ color: '#e9313a' }} />
                  </div>
                ) : (
                  <div>
                    {allConv?.length ? (
                      <div>
                        {allConv?.map((conv) => (
                          <Conversation conv={conv} key={conv.id} />
                        ))}
                      </div>
                    ) : (
                      <div className='text-center mt-4 '>
                        <h5>No Conversation yet</h5>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Col>
            <Col
              md={8}
              style={{
                border: '2px solid #e7e7e7',
                borderBottom: 'none',
                height: '70vh',
              }}
            >
              <Routes>
                <Route path='/' element={<NoSellerChat />}></Route>
                <Route path='/:seller_id' element={<ChatSection />}>
                  {' '}
                </Route>
              </Routes>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Message;
