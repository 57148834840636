import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { Header } from '../../../Header/Header';
import { url } from '../../../Helpers/Constant';
import NewPassword from '../NewPassword/NewPassword';
import { OtpMatch } from '../OtpMatch/OtpMatch';

const ForgotPassword = () => {
  const [error, setError] = useState('');
  const [token, setToken] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (phoneNumber.startsWith('01') && phoneNumber.length === 11) {
      fetch(`${url}/api/otp/send/forget`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phone: phoneNumber, user: 'buyer' }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            navigate('/forgetpassword/otp');
            setIsLoading(false);
          } else {
            setError(data.message);
            setIsLoading(false);
            setTimeout(() => {
              setError('');
            }, 5000);
          }
        });
    } else {
      setError('Invalid phone Number');
      setIsLoading(false);
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };
  return (
    <>
      <Header />
      <div className='mt-5'>
        <Container>
          <Row>
            <Routes>
              <Route
                path='/'
                element={
                  <Col md={4} className='mx-auto'>
                    <div className='my-5 text-center sign-up-form'>
                      <div className='mb-4'>
                        <h5 className='fw-bold'>Forget Password</h5>
                      </div>
                      <form action='' onSubmit={handleSubmit}>
                        <p className='fw-bold text-start'>Enter Your Number</p>
                        <input
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          required
                          placeholder='Enter Your Number'
                          className='my-1 w-100 px-2 py-2 sign-up-input'
                          type='number'
                        />

                        <div className='text-start ps-1'>
                          Back to
                          <Link
                            to='/login'
                            className='text-decoration-none ps-1'
                          >
                            login
                          </Link>
                        </div>

                        <div className='my-1 text-center'>
                          <small className='text-danger fw-bold'>{error}</small>
                        </div>
                        {isLoading ? (
                          <div className='loading-btn my-2'>
                            <div>
                              <input
                                type='submit'
                                className='my-2 submit-button'
                                disabled
                                value='Forget Password...'
                              />
                            </div>
                          </div>
                        ) : (
                          <input
                            className='my-2 submit-button'
                            type='submit'
                            value='Forget Password'
                          />
                        )}
                      </form>
                    </div>
                  </Col>
                }
              ></Route>

              <Route
                path='/otp'
                element={<OtpMatch phone={phoneNumber} setToken={setToken} />}
              ></Route>
              <Route
                path='/newpassword'
                element={<NewPassword phone={phoneNumber} token={token} />}
              ></Route>
            </Routes>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ForgotPassword;
