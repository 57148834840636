import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { AUTH_USER_SUCCESS, url } from '../../Helpers/Constant';
import { districts } from '../DistrictsData/districts';
import { useAuthContext } from '../../context/AuthContext';

const Register = () => {
  const { dispatch: AuthDispatch } = useAuthContext();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const [divisionID, setDivisionID] = useState('');
  const navigate = useNavigate();

  const onSubmit = (data) => {
    setIsLoading(true);
    if (data.phone.length === 11 && data.phone.startsWith('01')) {
      if (
        data.account_number.length === 11 &&
        data.account_number.startsWith('01')
      ) {
        fetch(`${url}/out/api/auth/buyer/registation`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              AuthDispatch({ type: AUTH_USER_SUCCESS, payload: data.data });
              localStorage.setItem(
                '__u_o',
                window.btoa(JSON.stringify(data.data))
              );
              navigate('/');
              setIsLoading(false);
            } else {
              setError(data.message);
              setTimeout(() => {
                setError('');
              }, 5000);
              setIsLoading(false);
            }
          });
      } else {
        setError('Invalid Bank Account Number');
        setIsLoading(false);
        setTimeout(() => {
          setError('');
        }, 5000);
      }
    } else {
      setError('Invalid Phone Number');
      setIsLoading(false);
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };
  return (
    <div>
      <Container>
        <Row>
          <Col md={4} className='mx-auto '>
            <div className='text-center sign-up-form '>
              <div className='mb-4'>
                <h4>Register</h4>
              </div>
              <form action='' onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <h6 className='fw-bold text-start '>Enter Your Name</h6>
                  <input
                    {...register('name')}
                    required
                    placeholder='Enter Your Name'
                    className='my-1 w-100 px-2 py-2 sign-up-input'
                    type='text'
                  />
                </div>
                <div className='py-2'>
                  <h6 className='fw-bold text-start '>
                    Enter Your Phone Number
                  </h6>
                  <input
                    {...register('phone')}
                    required
                    placeholder='Enter Your Phone Number'
                    className='my-1 w-100 px-2 py-2 sign-up-input'
                    type='number'
                  />
                </div>
                <div>
                  <h6 className='fw-bold text-start '>Enter Your Email</h6>
                  <input
                    {...register('email')}
                    required
                    placeholder=' Enter Your Email'
                    className='my-1 w-100 px-2 py-2 sign-up-input'
                    type='email'
                  />
                </div>
                <div>
                  <div className='mt-2'>
                    <h6 className='fw-bold text-start pb-1'>
                      Select Your Division
                    </h6>
                    <select
                      onClick={(e) => setDivisionID(e.target.value)}
                      className='sign-up-input w-100'
                      required
                      {...register('division')}
                    >
                      <option value=''>Select Division</option>
                      <option value='Barisal'>Barisal</option>
                      <option value='Chittagong'>Chittagong</option>
                      <option value='Dhaka'>Dhaka</option>
                      <option value='Khulna'>Khulna</option>
                      <option value='Mymensingh'>Mymensingh</option>
                      <option value='Rajshahi'>Rajshahi</option>
                      <option value='Rangpur'>Rangpur</option>
                      <option value='Rajshahi'>Sylhet</option>
                    </select>
                  </div>

                  {divisionID && (
                    <div className='mt-2'>
                      <h6 className='fw-bold text-start pb-1'>
                        Select Your city
                      </h6>

                      <select
                        className='sign-up-input w-100'
                        required
                        {...register('city')}
                      >
                        {districts.map(
                          (dis) =>
                            divisionID === dis.division_name && (
                              <option value={dis.name} key={dis.id}>
                                {dis.name}
                              </option>
                            )
                        )}
                      </select>
                    </div>
                  )}
                </div>
                <div className='pt-2'>
                  <h6 className='fw-bold text-start '>Enter Your Address</h6>
                  <input
                    {...register('address')}
                    required
                    placeholder='Enter  Your Address'
                    className='my-1 w-100 px-2 py-2 sign-up-input'
                    type='text'
                  />
                </div>
                <div className='mt-2'>
                  <h6 className='fw-bold text-start '>Enter Your Post Code</h6>
                  <input
                    {...register('post_code')}
                    required
                    placeholder=' Enter Your Post Code'
                    className='my-1 w-100 px-2 py-2 sign-up-input'
                    type='number'
                  />
                </div>

                <div className='mt-2'>
                  <h6 className='fw-bold text-start pb-1'>Select Bank Name</h6>
                  <select
                    className='sign-up-input w-100'
                    required
                    {...register('bank_name')}
                  >
                    <option value=''>Select Bank</option>
                    <option value='bkash'>Bkash</option>
                    <option value='nagad'>Nagad</option>
                    <option value='upay'>Upay</option>
                  </select>
                </div>

                <div>
                  <h6 className='fw-bold text-start '>
                    Enter Bank Account Number
                  </h6>
                  <input
                    {...register('account_number')}
                    required
                    placeholder=' Enter Bank Account Number'
                    className='my-1 w-100 px-2 py-2 sign-up-input'
                    type='text'
                  />
                </div>

                <div className='pt-2'>
                  <h6 className='fw-bold text-start '>Enter Password</h6>
                  <input
                    {...register('password')}
                    required
                    placeholder='Enter Password'
                    className='my-1 w-100 px-2 py-2 sign-up-input'
                    type='password'
                  />
                </div>

                <div className='text-start ps-1'>
                  Back to
                  <Link to='/login' className='text-decoration-none ps-1'>
                    login
                  </Link>
                </div>

                {error && (
                  <div className='mt-2 mb-1 text-center'>
                    <small className='text-danger fw-bold'>{error}</small>
                  </div>
                )}

                {isLoading ? (
                  <div className='loading-btn my-2'>
                    <div>
                      <input
                        type='submit'
                        className='my-2 submit-button'
                        disabled
                        value='Submit...'
                      />
                    </div>
                  </div>
                ) : (
                  <input
                    className='my-2 submit-button'
                    type='submit'
                    value='Submit'
                  />
                )}
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Register;
