import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { url } from '../Helpers/Constant';
import { MdLocationOn } from 'react-icons/md';
import { IoMdPeople } from 'react-icons/io';
import ShowFreelancing from '../ShowFreelancing/ShowFreelancing';
import { Header } from '../Header/Header';
import moment from 'moment/moment';

const SellerProfile = () => {
  const { seller_id } = useParams();
  const [seller, SetSeller] = useState({});
  const [sellerGig, setSellerGig] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`${url}/out/api/seller/get/a/seller/${seller_id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          SetSeller(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });

    // get all gig by seller

    fetch(`${url}/out/api/gig/get/by/seller/${seller_id}/approved`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setSellerGig(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  }, [seller_id]);
  useEffect(() => {
    document.title = 'Seller-profile | Freelancing ';
  }, []);

  return (
    <div>
      <Header />
      <div className='seller-profile'>
        <Container>
          <div>
            <Row>
              <Col md={4}>
                <div className='mt-5 ps-5 ps-md-0'>
                  <Card style={{ width: '18rem' }}>
                    <Card.Body>
                      <div className='text-center'>
                        <div className='mb-3'>
                          <img
                            className='rounded-circle '
                            width={150}
                            height={160}
                            src={
                              seller?.photo
                                ? `${url}/get/image/freelancing_seller_files/${seller?.photo}`
                                : '/assets/manavater.png'
                            }
                            alt=''
                          />
                        </div>
                        <b className='fs-4'>{seller?.name}</b>
                      </div>
                      <hr />
                      <div>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div className='d-flex align-items-center gap-2'>
                            <MdLocationOn />
                            <p>From</p>
                          </div>
                          <div>{seller.city}</div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center mt-2'>
                          <div className='d-flex align-items-center gap-2'>
                            <IoMdPeople />
                            <p>Member since </p>
                          </div>
                          <div>
                            {moment(
                              `${seller.apply_date}`,
                              'YYYYMMDD'
                            ).fromNow()}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
              <Col md={8}>
                <div className='pt-5'>
                  <h3 className='mb-3 fw-bold'>{`${seller?.name}'s Gigs`}</h3>
                  {loading ? (
                    <div className='text-center  mt-5'>
                      <Spinner
                        animation='border'
                        style={{ color: '#e9313a' }}
                      />
                    </div>
                  ) : (
                    <div>
                      <Row md={3} lg={3} xs={1}>
                        {sellerGig.map((gig) => {
                          gig.photo = seller.photo;
                          gig.name = seller.name;
                          return <ShowFreelancing key={gig.gig_id} gig={gig} />;
                        })}
                      </Row>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default SellerProfile;
