import React, { useState } from 'react';
import { Header } from '../../Header/Header';
import { Col, Container, Row } from 'react-bootstrap';
import { url } from '../../Helpers/Constant';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import MatchOtp from '../MatchOtp/MatchOtp';
import Register from '../Register/Register';

export const Join = () => {
  const [error, setError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (phoneNumber.startsWith('01') && phoneNumber.length === 11) {
      fetch(`${url}/api/otp/send/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phone: phoneNumber, user: 'buyer' }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            navigate('/join/otp');
            setIsLoading(false);
          } else {
            setError(data.message);
            setIsLoading(false);
            setTimeout(() => {
              setError('');
            }, 5000);
          }
        });
    } else {
      setError('Invalid phone Number');
      setIsLoading(false);
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };

  return (
    <>
      <Header />
      <div className='mt-5'>
        <Container>
          <Row>
            <Routes>
              <Route
                path='/'
                element={
                  <Col md={4} className='mx-auto'>
                    <div className='my-5 text-center sign-up-form'>
                      <form action='' onSubmit={handleSubmit}>
                        <h6 className='fw-bold mb-3 '>Enter Your Number</h6>
                        <input
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          required
                          placeholder='Enter Your Number'
                          className='my-1 w-100 px-2 py-2 sign-up-input'
                          type='number'
                        />

                        <div className='my-1 text-center'>
                          <small className='text-danger fw-bold'>{error}</small>
                        </div>
                        {isLoading ? (
                          <div className='loading-btn my-2'>
                            <div>
                              <input
                                type='submit'
                                className='my-2 submit-button'
                                disabled
                                value='Joining...'
                              />
                            </div>
                          </div>
                        ) : (
                          <input
                            className='my-2 submit-button'
                            type='submit'
                            value='Join'
                          />
                        )}

                        <hr />
                        <div className='text-center my-2'>
                          Have an account?
                          <Link
                            to='/login'
                            className='text-decoration-none ps-1'
                          >
                            Log in
                          </Link>
                        </div>
                      </form>
                    </div>
                  </Col>
                }
              ></Route>
              <Route
                path='/otp'
                element={<MatchOtp phone={phoneNumber} />}
              ></Route>
              <Route path='/register' element={<Register />}></Route>
            </Routes>
          </Row>
        </Container>
      </div>
    </>
  );
};
