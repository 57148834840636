import React from 'react';
import { useAuthContext } from '../../context/AuthContext';
import { url } from '../../Helpers/Constant';

export const ShowMessage = ({ msg, singleConv }) => {
  const { user } = useAuthContext();
  const { message, sender_id, reciever_id, atc } = msg;
  return (
    <div className={user.id === sender_id ? 'text-end ' : 'text-start  '}>
      {message && atc.length ? (
        <div className='mt-3 mb-4'>
          <div className='me-2'>
            <p
              style={{ marginLeft: '35px' }}
              className={
                user.id === sender_id
                  ? 'text-end  Seller-sms  '
                  : 'text-start buyer-sms '
              }
            >
              {message}
            </p>
            <div className={user.id === sender_id ? 'text-end' : 'text-start'}>
              <div
                className={
                  user.id === sender_id
                    ? 'd-flex flex-wrap align-items-center justify-content-end'
                    : 'd-flex flex-wrap align-items-center justify-content-start'
                }
              >
                {reciever_id === user.id && (
                  <img
                    className='rounded-circle mt-5'
                    width={30}
                    height={30}
                    src={
                      singleConv?.buyer_photo
                        ? `${url}/get/image/freelancing_buyer_files/${singleConv?.buyer_photo}`
                        : 'https://www.berkshireaerial.com/wp-content/uploads/2019/01/facebook-anon-female-icon.jpg'
                    }
                    alt=''
                  />
                )}
                {atc?.map((img, index) => {
                  if (typeof img === 'object') {
                    return (
                      <div key={index} className='ps-2 pe-2'>
                        <img
                          width={180}
                          height={120}
                          src={URL.createObjectURL(img)}
                          alt=''
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={index}
                        style={{ marginTop: '8px' }}
                        className={
                          user.id === sender_id ? 'text-end pe-2' : 'text-start'
                        }
                      >
                        <div>
                          <img
                            className='ps-2'
                            width={180}
                            height={120}
                            src={`${url}/get/image/freelancing_convo_files/${img}`}
                            alt=''
                          />
                        </div>
                      </div>
                    );
                  }
                })}
              </div>

              {/* send by me or not  */}
              <div className={`text-muted small pe-2 `}>
                {user.id === sender_id ? 'You' : ''}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {!message ? (
            <div>
              <div>
                <div
                  className={
                    user.id === sender_id
                      ? 'd-flex flex-wrap align-items-center justify-content-end'
                      : 'd-flex flex-wrap align-items-center justify-content-start'
                  }
                >
                  <div className='ps-2'>
                    {reciever_id === user.id && (
                      <img
                        className='rounded-circle mt-5  '
                        width={30}
                        height={30}
                        src={`${url}/get/image/freelancing_seller_files/${singleConv?.seller_photo}`}
                        alt=''
                      />
                    )}
                  </div>

                  {atc?.map((img, index) => {
                    if (typeof img === 'object') {
                      return (
                        <div key={index} className='ps-2 pe-2 mt-3'>
                          <img
                            style={{ marginTop: '25px' }}
                            width={180}
                            height={120}
                            src={URL.createObjectURL(img)}
                            alt=''
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={index}
                          style={{ marginTop: '25px' }}
                          className={
                            user.id === sender_id
                              ? 'text-end pe-2'
                              : 'text-start'
                          }
                        >
                          <div>
                            <img
                              className='ps-2'
                              width={180}
                              height={120}
                              src={`${url}/get/image/freelancing_convo_files/${img}`}
                              alt=''
                            />
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
              <div className={`text-muted small `}>
                {user.id === sender_id ? 'You' : ''}
              </div>
            </div>
          ) : (
            <div className='ps-2'>
              {reciever_id === user.id && (
                <img
                  className='rounded-circle'
                  width={30}
                  height={30}
                  src={`${url}/get/image/freelancing_seller_files/${singleConv?.seller_photo}`}
                  alt=''
                />
              )}
              <p
                className={
                  user.id === sender_id
                    ? 'text-end  Seller-sms '
                    : 'text-start buyer-sms'
                }
              >
                {message}
              </p>

              <div className={`text-muted small `}>
                {user.id === sender_id ? 'You' : ''}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
