import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { url } from '../../Helpers/Constant';

const MatchOtp = ({ phone }) => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    setIsLoading(true);
    if (data.otp.length === 6) {
      fetch(`${url}/api/otp/match`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          otp_creds: {
            phone: phone,
            otp: data.otp,
            type: 'register',
          },
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            navigate('/join/register');

            setIsLoading(false);
          } else {
            setError(data.message);
            setTimeout(() => {
              setError('');
            }, 5000);
            setIsLoading(false);
          }
        });
    } else {
      setError('Invalid OTP');
      setIsLoading(false);
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={4} className='mx-auto'>
            <div className='my-5 text-center sign-up-form'>
              <form action='' onSubmit={handleSubmit(onSubmit)}>
                <h6 className='fw-bold mb-3 '>Enter OTP Number</h6>
                <input
                  {...register('otp')}
                  required
                  placeholder='Enter OTP Number'
                  className='my-1 w-100 px-2 py-2 sign-up-input'
                  type='number'
                />

                {error && (
                  <div className='mt-2 mb-1 text-center'>
                    <small className='text-danger fw-bold'>{error}</small>
                  </div>
                )}

                {isLoading ? (
                  <div className='loading-btn my-2'>
                    <div>
                      <input
                        type='submit'
                        className='my-2 submit-button'
                        disabled
                        value='Submit...'
                      />
                    </div>
                  </div>
                ) : (
                  <input
                    className='my-2 submit-button'
                    type='submit'
                    value='Submit'
                  />
                )}
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MatchOtp;
