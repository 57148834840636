import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../context/AuthContext';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { url } from '../Helpers/Constant';
import './OrderService.css';

const OrderService = ({ sGig }) => {
  const { register, handleSubmit, reset } = useForm();
  const { user } = useAuthContext();
  const [image, setImage] = useState({ image1: '', image2: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const { title, price, gig_id, d_time, d_time_type, seller_id } = sGig;

  const onSubmit = (data) => {
    setIsLoading(true);
    setError('');
    data.gig_id = gig_id;
    data.d_time = d_time;
    data.price = price;
    data.d_time_type = d_time_type;
    data.seller_id = seller_id;

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    if (image.image1) formData.append('image_1', image.image1);
    if (image.image2) formData.append('image_2', image.image2);

    fetch(`${url}/out/api/buyer/create/order/${user.id}`, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setMessage(data.message);
          setSuccess(true);
          reset();
          timeOut();
          setIsLoading(false);
        } else {
          setError(data.message);
          setIsLoading(false);
          setTimeout(() => {
            // reset();
            // setImage((image.image1 = ''), (image.image2 = ''));
            setError('');
          }, 7000);
        }
      });
  };
  const timeOut = () => {
    setTimeout(() => {
      setMessage('');
    }, 8000);
  };
  const hadelRemoved = (img) => {
    if (img === 1) {
      setImage({ ...image, image1: '' });
    }
    if (img === 2) {
      setImage({ ...image, image2: '' });
    }
  };
  useEffect(() => {
    document.title = 'Order-Services | Freelancing';
  }, []);
  return (
    <div>
      <div className='my-5'>
        <Row className='g-5'>
          <Col md={7} sm={12}>
            <div className='text-center'>
              <h2> Payment Method </h2>
            </div>
          </Col>
          <Col md={5} sm={12}>
            {success ? (
              <div>
                <p className='text-success text-center mt-2 fs-3'>{message}</p>

                <div className='text-center'>
                  <Link
                    className=' text-decoration-none pointer'
                    to='/allservices/allcategory'
                  >
                    Back to all Gig
                  </Link>
                </div>
              </div>
            ) : (
              <div>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className='orderService'
                >
                  <div>
                    <label className='fw-bold'>Gig Title</label>
                    <input
                      defaultValue={title}
                      {...register('title')}
                      className='order-service-input'
                    />
                  </div>
                  <div>
                    <label className='my-2 fw-bold'>
                      Requirement Description
                    </label>
                    <textarea
                      {...register('o_desc')}
                      className='order-service-input w-100'
                      style={{ height: '150px' }}
                      placeholder='Requirement Description'
                    />
                  </div>
                  <div>
                    <label className='my-2 fw-bold'>
                      Ref_link (www.example.com){' '}
                    </label>
                    <input
                      {...register('ref_link')}
                      className='order-service-input'
                      placeholder='Ref_link (www.example.com)'
                    />
                  </div>
                  <div>
                    <label className='my-2 fw-bold '>Example Image/File </label>

                    {/* get image  */}
                    <div className='d-flex gap-4'>
                      <div>
                        {image.image1 ? (
                          <div className=' position-relative'>
                            <div>
                              <img
                                width={130}
                                height={100}
                                src={URL.createObjectURL(image.image1)}
                                alt=''
                              />
                            </div>
                            <h5
                              className=' position-absolute  gig-image-remove-button pointer'
                              onClick={() => hadelRemoved(1)}
                            >
                              x
                            </h5>
                          </div>
                        ) : (
                          <div className='position-relative  text-success '>
                            <label htmlFor='image' className='pointer'>
                              <div className='service-up-image-input'>
                                <div className='mx-3 mt-2 '>
                                  <img
                                    className='mx-2'
                                    src='/assets/freelancing/AddImage.png'
                                    alt=''
                                  />
                                  <h6>Add new</h6>
                                </div>
                              </div>
                              <input
                                className='d-none'
                                type='file'
                                id='image'
                                accept='.jpg, .png, .jpeg'
                                onChange={(e) =>
                                  e.target.files[0] &&
                                  setImage({
                                    ...image,
                                    image1: e.target.files[0],
                                  })
                                }
                              />
                            </label>
                          </div>
                        )}
                      </div>
                      <div>
                        {image.image2 ? (
                          <div className=' position-relative'>
                            <div>
                              <img
                                width={130}
                                height={100}
                                src={URL.createObjectURL(image.image2)}
                                alt=''
                              />
                            </div>
                            <h5
                              className=' position-absolute service-image-remove-button pointer'
                              onClick={() => hadelRemoved(2)}
                            >
                              x
                            </h5>
                          </div>
                        ) : (
                          <div className='position-relative  text-success '>
                            <label htmlFor='image' className='pointer'>
                              <div className='service-up-image-input'>
                                <div className='mx-3 mt-2 '>
                                  <img
                                    className='mx-2'
                                    src='/assets/freelancing/AddImage.png'
                                    alt=''
                                  />
                                  <h6>Add new</h6>
                                </div>
                              </div>
                              <input
                                className='d-none'
                                type='file'
                                id='image'
                                accept='.jpg, .png, .jpeg'
                                onChange={(e) =>
                                  e.target.files[0] &&
                                  setImage({
                                    ...image,
                                    image2: e.target.files[0],
                                  })
                                }
                              />
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    {message && (
                      <p className='text-success text-center mt-2'>{message}</p>
                    )}
                    {error && (
                      <h5 className='text-danger text-center mt-3'>{error}</h5>
                    )}
                  </div>
                  {isLoading ? (
                    <input
                      type='submit'
                      className='order-service-input'
                      value='Please wait...'
                      disabled
                    />
                  ) : (
                    <input className='order-service-input mt-4' type='submit' />
                  )}
                </form>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OrderService;
