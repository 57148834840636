import { AUTH_USER_SUCCESS, USER_PROFILE_UPDATE } from '../Helpers/Constant';

const reducer = (state, action) => {
  switch (action.type) {
    case AUTH_USER_SUCCESS:
      return { ...state, user: action.payload };
    case USER_PROFILE_UPDATE:
      return { ...state, user: { ...state.user, ...action.payload } };
    default:
      throw new Error(`No matching action type - ${action.type}`);
  }
};

export default reducer;
