import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
export const ServicesPhone = ({ setCategory, category }) => {
  return (
    <div className='mb-4'>
      <Accordion>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>Gig Category</Accordion.Header>
          <Accordion.Body>
            <ul className='ps-0 categories  '>
              {/* {category.map((sCategory) => {
                    const { id, name } = sCategory;

                    return (
                      <li key={id}>
                        <NavLink
                          to={`/allservices/allcategory/${name}`}
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          {name}
                        </NavLink>
                      </li>
                    );
                  })} */}

              <li>
                <div>
                  <div
                    onClick={() => {
                      category === 'Application Development'
                        ? setCategory('')
                        : setCategory('Application Development');
                    }}
                  >
                    <div className='d-flex justify-content-between'>
                      <div className='pointer fw-bold'>
                        Application Development
                      </div>
                      <div>
                        {category === 'Application Development' ? (
                          <IoIosArrowDown className='pointer' />
                        ) : (
                          <IoIosArrowForward className='pointer' />
                        )}
                      </div>
                    </div>
                  </div>
                  {category === 'Application Development' && (
                    <ul>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/mobileapps'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Mobile Apps
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/webapps'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Web Apps
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/desktopapplication'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Desktop Application
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/gamedevelopment'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Game Development
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/webprogramming'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Web programming
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/wordPress'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          WordPress
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </div>
              </li>
              <li>
                <div>
                  <div
                    onClick={() => {
                      category === 'GraphicsDesign'
                        ? setCategory('')
                        : setCategory('GraphicsDesign');
                    }}
                  >
                    <div className='d-flex justify-content-between'>
                      <div className='pointer fw-bold'>Graphics & Design</div>
                      <div>
                        {category === 'GraphicsDesign' ? (
                          <IoIosArrowDown className='pointer' />
                        ) : (
                          <IoIosArrowForward className='pointer' />
                        )}
                      </div>
                    </div>
                  </div>
                  {category === 'GraphicsDesign' && (
                    <ul>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/logodesign'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Logo Design
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/websitedesign'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Website Design
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/appdesign'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          App Design
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/uxdesign'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Ux design
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/icondesign'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Icon Design
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/bannerdesign'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Banner Design
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/templatedesign'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Template Design
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/fashiondesign'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Fashion Design
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </div>
              </li>
              <li>
                <div>
                  <div
                    onClick={() => {
                      category === 'Explore Digital Marketing'
                        ? setCategory('')
                        : setCategory('Explore Digital Marketing');
                    }}
                  >
                    <div className='d-flex justify-content-between'>
                      <div className='pointer fw-bold'>
                        Explore Digital Marketing
                      </div>
                      <div>
                        {category === 'Explore Digital Marketing' ? (
                          <IoIosArrowDown className='pointer' />
                        ) : (
                          <IoIosArrowForward className='pointer' />
                        )}
                      </div>
                    </div>
                  </div>
                  {category === 'Explore Digital Marketing' && (
                    <ul>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/seo'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Search Engine Optimization (SEO)
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/localseo'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Local SEO
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/socialmediamarketing'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Social Media Marketing
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/youtubechannelmarketing'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Youtube Channel Marketing
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/youtubevideomarketing'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Youtube Video Marketing
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/guestposting'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Guest Posting
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/emailmarketing'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Email Marketing
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/textmessagemarketing'
                          className={({ isActive }) =>
                            isActive ? 'active-category fs-5' : ''
                          }
                        >
                          Text Message Marketing
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </div>
              </li>
              <li>
                <div>
                  <div
                    onClick={() => {
                      category === 'Writing Translation'
                        ? setCategory('')
                        : setCategory('Writing Translation');
                    }}
                  >
                    <div className='d-flex justify-content-between'>
                      <div className='pointer fw-bold'>
                        Writing & Translation
                      </div>
                      <div>
                        {category === 'Writing Translation' ? (
                          <IoIosArrowDown className='pointer' />
                        ) : (
                          <IoIosArrowForward className='pointer' />
                        )}
                      </div>
                    </div>
                  </div>
                  {category === 'Writing Translation' && (
                    <ul>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/articlesbolgpost'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Articles and Blog Posts
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/websitecontent'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Website Content
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/bookebook'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Book and eBook writing
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/speechwriting'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Speech writing
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/scriptwriting'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Script Writing
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          to='/allservices/allcategory/podcastwriting'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Podcast Writing
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/resumewriting'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Resume Writing
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/coverletters'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Cover letters
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </div>
              </li>
              <li>
                <div>
                  <div
                    onClick={() => {
                      category === 'Video Animation'
                        ? setCategory('')
                        : setCategory('Video Animation');
                    }}
                  >
                    <div className='d-flex justify-content-between'>
                      <div className='pointer fw-bold'>
                        Explore Video & Animation
                      </div>
                      <div>
                        {category === 'Video Animation' ? (
                          <IoIosArrowDown className='pointer' />
                        ) : (
                          <IoIosArrowForward className='pointer' />
                        )}
                      </div>
                    </div>
                  </div>
                  {category === 'Video Animation' && (
                    <ul>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/shortvideoads'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Short Video Ads
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/socialmediavideos'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Social media Videos
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/lyricmusicvideos'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Lyric and music videos
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/slideshowvideos'
                          className={({ isActive }) =>
                            isActive ? 'active-category ' : ''
                          }
                        >
                          Slideshow videos
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/corporatevideos'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Corporate videos
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          to='/allservices/allcategory/unboxingvideos'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Unboxing videos
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/animationvideos'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Animation videos
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/allservices/allcategory/logoanimation'
                          className={({ isActive }) =>
                            isActive ? 'active-category' : ''
                          }
                        >
                          Logo animation
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </div>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
