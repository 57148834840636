import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../context/AuthContext';
import { Header } from '../Header/Header';
import { url } from '../Helpers/Constant';
import './Profile.css';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import ShowAllOrders from './ShowAllOrders';
import { Link } from 'react-router-dom';
import ProfileEditModal from './ProfileEditModal';

export const Profile = () => {
  const { user } = useAuthContext();
  const [allorders, setAllOrders] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // get single buyer all orders
  useEffect(() => {
    fetch(`${url}/out/api/gig-order/get/all/buyer/${user.id}?limit=10&skip=0`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setAllOrders(data.data);
        }
      });
  }, [user.id]);

  useEffect(() => {
    document.title = 'Profile | Freelancing ';
  }, []);
  return (
    <div className='buyer-account-div'>
      <Header />
      <div>
        <Container>
          <Row className='mt-4'>
            <Col md={5} sm={12}>
              <div className='buyer-profile mb-4'>
                <div className='text-center profile-left-side-div'>
                  <div>
                    {user.photo ? (
                      <img
                        className='mt-4 rounded-circle'
                        width={150}
                        height={160}
                        src={`${url}/get/image/freelancing_buyer_files/${user.photo}`}
                        alt=''
                      />
                    ) : (
                      <img
                        className='mt-4 rounded-circle'
                        width={150}
                        height={160}
                        src={'/assets/manavater.png'}
                        alt=''
                      />
                    )}

                    <h4 className='mt-3'>{user.name}</h4>
                  </div>

                  <div className=' profile-edit-section '>
                    <button className='edit-button' onClick={handleShow}>
                      Edit Profile
                    </button>
                  </div>
                </div>
                <div className='my-2 mx-3'>
                  <p>
                    Bank Name:
                    {user?.bank_name ? (
                      <b className='ps-1'>{user?.bank_name}</b>
                    ) : (
                      <b className='ps-1'>Unknown</b>
                    )}
                  </p>
                  <p>
                    Account Number:
                    {user?.account_number ? (
                      <b className='ps-1'>{user?.account_number}</b>
                    ) : (
                      <b className='ps-1'>Unknown</b>
                    )}
                  </p>
                </div>
              </div>
            </Col>
            <Col md={7}>
              <div>
                <div className='buyer-order-text mb-4'>
                  <h3 className='ps-2 mt-2 fw-bold fs-5'>All Oder's</h3>
                </div>
                <div className='buyer-order-details mb-4 px-2 pt-4 pb-4'>
                  {allorders?.length ? (
                    <div>
                      <Accordion defaultActiveKey='0'>
                        {allorders?.map((order, index) => (
                          <ShowAllOrders
                            key={index}
                            order={order}
                            setAllOrders={setAllOrders}
                            allorders={allorders}
                          ></ShowAllOrders>
                        ))}
                      </Accordion>
                    </div>
                  ) : (
                    <div>
                      <p>It seems that you don't have any Order</p>

                      <div className='mt-4 '>
                        <Link
                          className=' text-decoration-none'
                          to='/allservices/allcategory'
                        >
                          Order Now
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <ProfileEditModal show={show} handleClose={handleClose} />
    </div>
  );
};
