import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Carousel } from 'react-bootstrap';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';
import { url } from '../Helpers/Constant';
import FadeLoader from 'react-spinners/FadeLoader';
import ShowSpinner from '../ShowSpinner/ShowSpinner';
import ContactSellerModel from '../ContactSellerModel/ContactSellerModel';
import OrderService from '../OrderService/OrderService';
import { Header } from '../Header/Header';
import PrivateRoute from '../AuthenticationPage/PrivateRoute/PrivateRoute';

const SingleServices = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuthContext();
  const { id } = useParams();

  const [sGig, setSGig] = useState({});
  const [gigImg, setGigImg] = useState([]);

  // buyer check

  // get single gig
  useEffect(() => {
    setLoading(true);
    if (id) {
      fetch(`${url}/out/api/gig/get/one/${id}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setSGig(data.data);
            setGigImg(data.data.images);
            setLoading(false);
          }
        });
    }
  }, [id]);

  // distructure
  const {
    seller_name,
    description,
    title,
    seller_photo,
    price,
    gig_id,
    seller_id,
    ft_img,
  } = sGig;

  useEffect(() => {
    document.title = 'Single-Services | Freelancing';
  }, []);
  return (
    <div>
      <Header />
      <Container>
        <Routes>
          <Route
            path='/'
            element={
              <div className='py-5'>
                <Row>
                  <Col md={7}>
                    <div className=''>
                      <div>
                        <h3>{title}</h3>

                        <div>
                          <Link
                            to={`/allservices/seller/${seller_id}`}
                            className=' text-decoration-none'
                          >
                            <div className='d-flex gap-2 mt-3 align-items-center'>
                              {seller_photo ? (
                                <img
                                  src={`${url}/get/image/freelancing_seller_files/${seller_photo}`}
                                  alt=''
                                  className='img-fluid seller-img '
                                />
                              ) : (
                                <FadeLoader />
                              )}

                              <h6>{seller_name}</h6>
                            </div>
                          </Link>
                        </div>

                        {/* <Col md={7} className='mx-auto'> */}
                        <div className='mt-4 align-items-center '>
                          <Container>
                            {gigImg.length ? (
                              <Carousel>
                                {gigImg.map((img, id) => {
                                  return (
                                    <Carousel.Item key={id}>
                                      <img
                                        height={300}
                                        style={{ objectFit: 'contain' }}
                                        className='w-100'
                                        src={`${url}/get/image/freelancing_gig_files/${img.img_name}`}
                                        alt=''
                                      />
                                    </Carousel.Item>
                                  );
                                })}
                              </Carousel>
                            ) : (
                              <div className='text-center'>
                                <img
                                  className='img-fluid h-100'
                                  src={`${url}/get/image/freelancing_gig_files/${ft_img}`}
                                  alt=''
                                />
                              </div>
                            )}
                          </Container>
                        </div>

                        <div className='my-5'>
                          <h1>About This Gig</h1>
                          <div
                            className='mt-4'
                            style={{ textAlign: 'justify' }}
                          >
                            <p>{description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={5} className='pt-sm-5 pt-md-0 '>
                    <div className='outsourcing-wrapper'>
                      <div className='outsourcing-cart  '>
                        <div className='package'>
                          <div className='d-flex justify-content-around package-list'>
                            <h2 className='pointer'>Basic</h2>
                          </div>
                        </div>
                        {/* package price and start */}
                        <div className='d-flex mt-5 justify-content-around '>
                          {/* {active === 'Basic' && ( */}
                          <div className='d-flex  '>
                            <h4>Starter Package 🥉</h4>
                            <p>&#2547; {price}</p>
                          </div>
                          {/* )} */}
                        </div>
                        <div className='text-center mt-5 mb-2'>
                          {loading ? (
                            <div className='loading-btn my-2'>
                              <div className=' d-flex align-items-center justify-content-center '>
                                <ShowSpinner />
                              </div>
                            </div>
                          ) : (
                            <Link to={`/allservices/${gig_id}/gig-order`}>
                              <Button
                                className='outsourcing-oder-button'
                                variant='primary'
                              >
                                Order &#2547; {price}
                              </Button>
                            </Link>
                          )}
                        </div>
                      </div>

                      {/* create Conversation with seller */}
                      <div>
                        {user?.account_number ? (
                          <ContactSellerModel sGig={sGig} />
                        ) : (
                          <div className='text-center mt-4'>
                            <p className='fs-5 fw-bold'>
                              Please login or register to contact seller
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            }
          ></Route>
          <Route
            path={`/gig-order`}
            element={
              <PrivateRoute>
                <OrderService sGig={sGig} />
              </PrivateRoute>
            }
          />
        </Routes>
      </Container>
    </div>
  );
};

export default SingleServices;
