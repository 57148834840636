import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { BiImageAdd } from 'react-icons/bi';
import { Link, useParams } from 'react-router-dom';
import { GoPrimitiveDot } from 'react-icons/go';
import { url } from '../../Helpers/Constant';
import { useAuthContext } from '../../context/AuthContext';
import { ShowMessage } from './ShowMessage';
import Clock from 'react-live-clock';
const ChatSection = () => {
  const { register, handleSubmit, reset } = useForm();
  const [allMessage, setAllMessage] = useState([]);
  const { user } = useAuthContext();
  const [files, setFiles] = useState([]);
  const [singleConv, setSingleConv] = useState({});
  const { seller_id } = useParams();

  // scroll on bottom
  const messageEnd = useCallback((node) => {
    if (node) {
      node.scrollIntoView({ smooth: true });
    }
  }, []);

  useEffect(() => {
    fetch(`${url}/out/api/convo/get/${user.id}/${seller_id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setSingleConv(data.data);
        }
      });
  }, [user.id, seller_id]);

  useEffect(() => {
    if (singleConv?.id) {
      fetch(`${url}/out/api/buyer/get/all/messages/${singleConv.id}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setAllMessage(data.data);
          }
        });
    }
  }, [singleConv?.id]);

  const onSubmit = (data) => {
    data.sender_id = singleConv?.buyer_id;
    data.reciever_id = singleConv?.seller_id;
    const fromData = new FormData();

    Object.keys(data).forEach((item) => {
      fromData.append(item, data[item]);
    });

    if (files.length) {
      files.forEach((file, index) => {
        const name = 'file' + (index + 1);
        fromData.append(name, file);
      });
    }

    fetch(`${url}/out/api/convo/send/message/${singleConv?.id}`, {
      method: 'POST',
      body: fromData,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          data.atc = files;
          setAllMessage((pevMessages) => [...pevMessages, data]);
          reset();
          setFiles([]);
        }
      });
  };
  const handelRemoveImag = (id) => {
    const removeImg = files?.filter((s, index) => index !== id);
    setFiles(removeImg);
  };

  return (
    <>
      <div className='all-conv mt-2  '>
        <div className='buyer-info'>
          <div>
            <GoPrimitiveDot className='fs-5' color='#9d9d9d' />
            <Link to={`/allservices/seller/${seller_id}`} className='fs-4 '>
              {singleConv?.seller_name}
            </Link>
          </div>
          <div className='ps-2'>
            {/* <p style={{ fontSize: '15px' }}> */}
            <span style={{ fontSize: '15px' }}>Local Time:</span>
            <Clock
              className='ps-2'
              format={'h:mm:ssa'}
              ticking={true}
              timezone={'Asia/Dhaka'}
            />{' '}
            {/* </p> */}
          </div>
          <hr className='w-100' />
        </div>
      </div>
      <div className='mb-4 chat-setcion '>
        <div>
          {allMessage?.length ? (
            <div>
              {allMessage?.map((msg, index) => {
                const lastMessage = allMessage.length - 1 === index;
                return (
                  <div ref={lastMessage ? messageEnd : null} key={index}>
                    <ShowMessage
                      key={index}
                      msg={msg}
                      singleConv={singleConv}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className='text-center mt-4 '>
              <h5>No Message Available </h5>
            </div>
          )}
        </div>
      </div>
      <div className='d-flex align-items-center gap-3'>
        <div>
          {files?.length ? (
            <div className='d-flex align-items-center gap-4 ps-2'>
              {files.map((img, index) => {
                return (
                  <div key={index}>
                    <div className='select-img-div'>
                      <img height={50} src={URL.createObjectURL(img)} alt='' />

                      <button
                        className='removeBtn remove-img'
                        onClick={() => handelRemoveImag(index)}
                      >
                        x
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {/* text input field  */}
      <div className='mt-2'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div>
              <div className='d-flex align-items-center'>
                <div>
                  {files.length > 2 ? (
                    ''
                  ) : (
                    <div className='position-relative '>
                      <label htmlFor='image' className='pointer'>
                        <BiImageAdd
                          color='#1c4594'
                          size={55}
                          title='Upload file or image'
                        />

                        <input
                          className='d-none'
                          type='file'
                          id='image'
                          onChange={(e) =>
                            e.target.files[0] &&
                            setFiles([...files, e.target.files[0]])
                          }
                        />
                      </label>
                    </div>
                  )}
                </div>
                <input
                  // required
                  type='text'
                  className='w-100'
                  {...register('message')}
                  style={{ height: '50px', borderRadius: '8px' }}
                  placeholder='Type your message'
                />

                <button type='submit' className=' buyer-sms-submit-button'>
                  Send
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ChatSection;
