import React, { useEffect, useState } from 'react';
import { url } from '../Helpers/Constant';
import { Row, Spinner } from 'react-bootstrap';
import ShowFreelancing from '../ShowFreelancing/ShowFreelancing';

const FreelancingCategory = ({ category }) => {
  const [categoryData, setCategoryData] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(
      `${url}/out/api/gig/get/status-and-category/approved/${category}?limit=30&skip=0`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setCategoryData(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  }, [category]);

  return (
    <div>
      <div className='mb-3'>
        <h3>{category}</h3>
      </div>
      <div>
        {loading ? (
          <div className='text-center  mt-5'>
            <Spinner animation='border' style={{ color: '#e9313a' }} />
          </div>
        ) : (
          <div>
            {categoryData?.length ? (
              <div>
                <Row md={3} lg={3} xs={1}>
                  {categoryData.map((gig) => (
                    <ShowFreelancing key={gig.gig_id} gig={gig} />
                  ))}
                </Row>
              </div>
            ) : (
              <div className='text-center mt-5'>
                <h5>No Gig available in this Category</h5>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FreelancingCategory;
