import React from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

const FreelancingHomeCategory = () => {
  const GigCategories = [
    {
      id: 1,
      categoryName: 'Mobile apps',
      name: 'Mobile Apps',
      route: '/allservices/allcategory/mobileapps',
    },
    {
      id: 2,
      categoryName: 'Articles and Blog Posts',
      name: 'Articles and Blog Posts',
      route: '/allservices/allcategory/articlesbolgpost',
    },
    {
      id: 3,
      categoryName: 'Resume Writing  ',
      name: 'Resume Writing ',
      route: '/allservices/allcategory/resumewriting',
    },
    {
      id: 4,
      categoryName: 'Logo Design',
      name: 'Logo Design',
      route: `/allservices/allcategory/logodesign`,
    },
    {
      id: 5,
      categoryName: 'Website Design',
      name: 'Website Design',
      route: `/allservices/allcategory/websitedesign`,
    },
    {
      id: 6,
      categoryName: 'Fashion Design',
      name: 'Fashion Design',
      route: '/allservices/allcategory/fashiondesign',
    },
    {
      id: 7,
      categoryName: 'Social Media Marketing ',
      name: 'Social Media Marketing ',
      route: '/allservices/allcategory/socialmediamarketing',
    },
    {
      id: 8,
      categoryName: 'Short Video Ads ',
      name: 'Short Video Ads ',
      route: '/allservices/allcategory/shortvideoads',
    },
    {
      id: 9,
      categoryName: 'Animation videos ',
      name: 'Animation videos ',
      route: '/allservices/allcategory/animationvideos',
    },
  ];
  return (
    <div>
      <Row md={3} xs={1}>
        {GigCategories.map((singleGigCategory) => {
          const { categoryName, id, route } = singleGigCategory;
          return (
            <Link to={route} className=' text-decoration-none' key={id}>
              <Col className='ps-5 md-ps-0'>
                <div className='mb-3  '>
                  <Card
                    style={{ width: '18rem' }}
                    className='mb-2 bg-light text-black'
                  >
                    <Card.Body>
                      <Card.Title className='text-center'>
                        {categoryName}
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Link>
          );
        })}
      </Row>
    </div>
  );
};

export default FreelancingHomeCategory;
